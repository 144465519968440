/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import useOutsideClick from "./outSideClick";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const languages = [
    { code: "en", name: "English" },
    { code: "tig", name: "Tigrigna" },
    { code: "arabic", name: "Arabic" },
    { code: "hindi", name: "Hindi" },
    { code: "korean", name: "Korean" },
    { code: "tig", name: "Amharic" },
    { code: "tig", name: "Oromo" },
    { code: "en", name: "French" },
    { code: "en", name: "German" },
    { code: "en", name: "Chinese" },
    { code: "en", name: "Portuguese " },
    { code: "en", name: "Urdu" },
    { code: "en", name: "Spanish" },
    { code: "en", name: "Japanese" },
    { code: "en", name: "Bengali" },
    { code: "en", name: "Russian" },
    { code: "en", name: "Swahili" },
    { code: "en", name: "Turkish" },
    { code: "en", name: "Kannada" },
  ];

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setIsDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleClickOutside = () => {
    setIsDropdownOpen(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const style = css`
    position: relative;
    .language-toggler {
      cursor: pointer;
      background-color: #0f5466;
      color: white;
      border: none;
      border-radius: 4px;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      transition: background-color 0.3s ease;
      display: flex;
      align-items: center;
    }

    .language-toggler:hover {
      background-color: #0056b3;
    }

    .dropdown-menu {
      display: ${isDropdownOpen ? "block" : "none"};
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #ffffff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      min-width: 160px;
      z-index: 1000;
      padding: 0.5rem 0;
      margin-top: 0.5rem;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    .dropdown-item {
      padding: 0.5rem 1rem;
      cursor: pointer;
      color: #333;
      text-align: left;
      transition: background-color 0.2s ease;
    }

    .dropdown-item:hover {
      background-color: #f0f0f0;
    }
  `;

  return (
    <div ref={ref} css={style}>
      <button className="language-toggler" onClick={handleDropdownToggle}>
        {languages.find((lang) => lang.code === i18n.language)?.name ||
          "Select Language"}
        <span style={{ marginLeft: "0.5rem" }}>▼</span>
      </button>
      <ul className="dropdown-menu">
        {languages.map((lang) => (
          <li
            key={lang.code}
            className="dropdown-item"
            onClick={() => handleLanguageChange(lang.code)}
          >
            {lang.name}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
