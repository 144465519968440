import { Routes, Route } from "react-router-dom";
// import HomePage from "./pages/HomePage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Policy from "./pages/Policy";
import Pagenotfound from "./pages/Pagenotfound";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";
import Dashboard from "./pages/user/Dashboard";
import PrivateRoute from "./components/Routes/Private";
import ForgotPasssword from "./pages/Auth/ForgotPasssword";
import AdminRoute from "./components/Routes/AdminRoute";
import AdminDashboard from "./pages/Admin/AdminDashboard";
import CreateCategory from "./pages/Admin/CreateCategory";
// import CreateProduct from "./pages/Admin/CreateProduct";
import DekiAshenda from "./pages/Admin/DekiAshenda";
import Orders from "./pages/user/MakePayment";
import Profile from "./pages/user/Profile";
import ChangePassword from "./pages/user/ChangePassword";
import MakePayment from "./pages/user/MakePayment";
import UploadPhoto from "./pages/user/UploadPhoto";

// import Test from "./pages/user/UploadPhoto";
import Requests from "./pages/Admin/Requests";
import UpdateProduct from "./pages/Admin/UpdateProduct";
// import Search from "./pages/Search";
import AdminOrders from "./pages/Admin/AdminOrders";

import AppHome from "./haf/pages/Home";
import Exemplary from "./haf/pages/Exemplary";
import AboutUs from "./haf/pages/AboutUs";
import Ashenda from "./haf/pages/Ashenda";
import Generate from "./haf/pages/Generate";
import Payment from "./haf/pages/Payment";

function App() {
  return (
    <>
      <Routes>
        {/* <Route path="/test" element={<MakePayment />} /> */}
        <Route path="/" element={<AppHome />} />
        <Route path="/exemplary" element={<Exemplary />} />
        <Route path="/:id" element={<AppHome />} />
        <Route path="/voter/payed" element={<AppHome />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/admin/generate/132" element={<Generate />} />
        {/* <Route path="/payed/hui" */}
        <Route path="ashenda" element={<Ashenda />} />
        <Route path="aboutus" element={<AboutUs />} />
        {/* <Route path="/product/:slug" element={<ProductDetails />} /> */}
        {/* <Route path="/categories" element={<Categories />} /> */}
        {/* <Route path="/cart" element={<CartPage />} /> */}
        {/* <Route path="/category/:slug" element={<CategoryProduct />} /> */}
        {/* <Route path="/search" element={<Search />} /> */}
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard />} />
          <Route path="user/orders" element={<Orders />} />
          <Route path="user/profile" element={<Profile />} />
          <Route path="user/upload" element={<UploadPhoto />} />
          <Route path="user/password" element={<ChangePassword />} />
          <Route path="user/pay" element={<MakePayment />} />
        </Route>
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-zone" element={<CreateCategory />} />
          <Route path="admin/product/:slug" element={<UpdateProduct />} />
          <Route path="admin/requests" element={<Requests />} />
          <Route path="admin/users" element={<DekiAshenda />} />
          <Route path="admin/orders" element={<AdminOrders />} />
        </Route>
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPasssword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="*" element={<Pagenotfound />} />
      </Routes>
    </>
  );
}

export default App;
