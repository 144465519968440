/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import customAxios from "../../config";

import SpinnerOverlay from "../components/General/SpinnerOverlay";

const style = css`
  .main-title {
    font-size: larger;
    font-weight: bold;
    padding: 2rem 1rem;
    text-align: center;
  }
  .methods {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .method {
    padding: 1rem;
    margin: 2rem;
    box-shadow: 0 0 2px 4px #0000001f;
    border-radius: 5px;
    width: 15rem;
    min-height: 10rem;
  }
  .method.recomended {
    box-shadow: 0 0 2px 4px darkcyan;
  }
  .recomended-badge {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }
  .recomended-badge div {
    text-transform: uppercase;
    border-radius: 5px;
    border: 2px solid darkcyan;
    color: darkcyan;
    font-size: medium;
    font-weight: bold;
    letter-spacing: 3px;
    padding: 0.5rem;
  }
  .price {
    font-size: larger;
    padding: 1rem 0;
  }
  .features {
    list-style: upper-roman;
    padding: 1rem;
  }
  .features li {
    font-size: small;
    /* list-style: none; */
    opacity: 0.7;
  }
  .title {
    font-weight: bold;
    font-size: large;
  }
  .method-description {
    opacity: 0.7;
    font-size: small;
  }
  .action {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .action button {
    width: 100%;
    border-radius: 5px;
    background-color: darkcyan;
    box-shadow: none;
    border: 0;
    color: white;
    font-size: large;
    padding: 0.25rem;
    margin: 1rem 0;
    &:hover {
      background-color: #005f5f;
    }
  }
  .action input {
    width: 100%;
    border-radius: 5px;
    box-shadow: none;
    border-color: darkcyan;
    &:focus {
      box-shadow: none;
    }
  }
`;
function Payment() {
  const [tCard, setTcard] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const tCardRef = useRef(null);
  const chapaPayRef = useRef(null);

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const fullName = queryParams.get("name");
  const phoneNo = queryParams.get("phone");
  const isd = queryParams.get("isd") === "true";

  function payWithChapa() {
    setIsLoading(true);
    const req = {
      name: fullName,
      phone: phoneNo,
    };
    //make a request to pay with Chapa
    customAxios
      .post("/vote/payWithChapa", req)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          chapaPayRef.current.textContent = "redirecting...";
          chapaPayRef.current.disabled = "true";
          setTimeout(() => {
            console.log("here: ", data.url);
            window.location.href = data.url;
          }, 1000);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    //redirect to url
  }

  function payWithTCard() {
    if (!tCardRef.current.value.trim()) {
      tCardRef.current.focus();
      return;
    }
    const reqObject = {
      name: fullName,
      phone: phoneNo,
      card: tCard,
    };
    setIsLoading(true);
    customAxios
      .post("/vote/payWithCard", reqObject)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          navigate("/?jp=true&method=tcard", { replace: true });
        } else {
          alert("card aleady used or unavailable");
        }
      })
      .catch((err) => {
        alert("something went wrong");
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function submitForFree() {
    navigate("/?jp=true&method=free", { replace: true });
  }

  function handleSetTcard() {
    const value = tCardRef.current.value;
    setTcard(value);
  }

  return (
    <div css={style}>
      <SpinnerOverlay isLoading={isLoading} />
      <div className="main-title">choose your payment method</div>
      <div className="methods">
        <div className="method">
          <div className="title">vote for free</div>
          <div className="method-description"></div>
          <div className="price">FREE</div>
          <div className="action">
            <button onClick={submitForFree}>submit for free</button>
          </div>
          <ul className="features">
            <li>only your first vote will be submitted</li>
            <li>you won't participate in the prize</li>
          </ul>
        </div>
        <div className="method recomended">
          {/* <div className="recomended-badge">
                    <div>
                        recomended
                    </div>
                </div> */}
          <div className="title">pay with chapa</div>
          <div className="method-description">telebirr, M-Pesa, CBEbirr</div>
          <div className="price">{isd ? "ETB 30" : "USD 1"}</div>
          <div className="action">
            <button ref={chapaPayRef} onClick={payWithChapa}>
              pay with chapa
            </button>
          </div>
          <ul className="features">
            <li>all your votes count</li>
            <li>you will participate in the prize</li>
          </ul>
        </div>
        <div className="method">
          <div className="title">pay with T-Card</div>
          <div className="method-description">tugza-card</div>
          <div className="price">{isd ? "ETB 30" : "USD 1"}</div>
          <div className="action">
            <input
              ref={tCardRef}
              onChange={handleSetTcard}
              className="form-control"
              type="number"
              placeholder="nos in T-Card"
            />
            <button onClick={payWithTCard}>pay with T-Card</button>
          </div>
          <ul className="features">
            <li>all your votes count</li>
            <li>you will participate in the prize</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Payment;
