/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";

import SpinnerOverlay from "../General/SpinnerOverlay";
import Voted from "./Voted";
import customAxios from "../../../config/index";

const style = css`
  /* border: 3px solid darkcyan; */
  position: relative;
  .choices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-bottom: 2rem;
  }
  .inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .inp {
    max-width: 12rem;
    margin: 1rem 0.5rem;
    box-shadow: none !important;
    :focus {
      border-color: darkcyan;
    }
  }
  .inp-warn {
    border-color: red !important;
  }
  .btn {
    background-color: darkcyan;
    color: white;
    margin: 1rem 0.5rem;
    box-shadow: none !important;
    :hover {
      background-color: #005c5c;
    }
  }
  .ltitle {
    border-top: 1px solid #efefef;
    padding: 0.5rem;
    /* padding-bottom: 0; */
    text-align: center;
    color: darkcyan;
    font-weight: bold;
  }
  .novotes {
    color: red;
    font-size: large;
  }
  @media (max-width: 515px) {
    .inputs {
      /* padding-top: 1.5rem; */
    }
    .inp,
    .btn {
      margin: 0.5rem;
    }
    .btn {
      max-width: 12rem;
    }
  }
`;

function NewSubmitModal({ show, isSubmitHit, setIsSubmitHit, onHide, votes, revokeVote, continueVoting, submitFree }) {
  const [areVotesSubmited, setAreVotesSubmited] = useState(false);

  function hide(){
    setAreVotesSubmited(false)
    onHide()
  }

    return (
        <Modal show={show} onHide={hide}>
            <Modal.Header>
                    <Modal.Title>
                        {" "}
                        <div style={{ fontSize: "large", fontWeight: "bold" }}>
                            your votes
                        </div>
                    </Modal.Title>
                </Modal.Header>
            <Modal.Body>
                <div className="cbx" css={style}>
                    <div className="choices">
                        <Voted
                            votes={votes}
                            revokeVote={revokeVote}
                            continueVoting={continueVoting}
                        />
                    </div>
                    {
                      areVotesSubmited?
                      <SubmitSuccess />
                      :
                      <VoterDetails votes={votes} 
                          isSubmitHit={isSubmitHit} 
                          setIsSubmitHit={setIsSubmitHit} 
                          setAreVotesSubmited={setAreVotesSubmited} 
                          submitFree={submitFree}
                      />
                    }
                </div>
            </Modal.Body>
        </Modal>
    );
}

function VoterDetails({ votes, isSubmitHit, setIsSubmitHit, setAreVotesSubmited, submitFree, }) {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [fullName, setFullName] = useState(localStorage.fullName ?? "");
    const [phoneNo, setPhoneNo] = useState(localStorage.phone ?? "");
    const [phoneNoValid, setPhoneNoValid] = useState(true);
    const [fullNameValid, setFullNameValid] = useState(true);

    const [isLoading, setIsLoading] = useState(false);

    const fullNameRef = useRef(null);
    const phoneNoRef = useRef(null);
    const noOfVotes = votes.length;

    useEffect(() => {
      const queryParams = new URLSearchParams(location.search);
      const fromPayment = queryParams.get("jp");
      if(fromPayment === 'true') {
        handleSubmitHit()
        navigate({ search: "" }, { replace: true });
      };
    }, []);

    function isValidPhoneNo() {
        return phoneNo.trim() !== "";
    }

    function isValidFullName() {
        return fullName.trim() !== "";
    }

    function handleSubmitHit() {
        if (!phoneNo.trim()) {
            console.log(phoneNo, ' ', phoneNo.trim())
            phoneNoRef.current.focus();
            return;
        }
        if (!fullName.trim()) {
            fullNameRef.current.focus();
            return;
        }
        if(votes.length === 0){
          alert("you havent voted yet");
          return;
        }
        if(submitFree){
          console.log("inside if")
          submitForFree();
          return;
        }
        console.log("here")
        verifyPayment();
    }

    function handleSetFullName() {
        const value = fullNameRef.current.value;
        if (!isValidFullName()) {
            setFullNameValid(false);
        } else {
            setFullNameValid(true);
        }
        setFullName(value);
        localStorage.fullName = value;
    }

    function handleSetPhoneNo() {
        const value = phoneNoRef.current.value;
        if (!isValidPhoneNo()) {
            setPhoneNoValid(false);
        } else {
            setPhoneNoValid(true);
        }
        setPhoneNo(value);
        localStorage.phone = value;
    }

    function submitForFree(){
      console.log('inside free submit')
      const reqObject = {
        phone: phoneNo, 
        name: fullName,
        actors: [votes[0].id], 
      }
      setIsLoading(true);
      customAxios
        .post("/vote/vote", reqObject)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setAreVotesSubmited(true);
            const currentUrl = window.location.href;
            window.history.replaceState({}, "", currentUrl.split("?", 1));
          }
          else{
            alert(data.message);
          }
        })
        .catch((e)=>{
          console.error(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    function submitVotes() {
      const reqObject = {
        phone: phoneNo,
        name: fullName,
        actors: votes
          .slice()
          .map((vote) => vote.id),
      };
      setIsLoading(true);
      customAxios
        .post("/vote/vote", reqObject)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setAreVotesSubmited(true);
            const currentUrl = window.location.href;
            window.history.replaceState({}, "", currentUrl.split("?", 1));
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    function verifyPayment(){
      const params = new URLSearchParams();
      params.append('name', fullName);
      params.append('phone', phoneNo);
      params.append('isd', 'true');
      //and submit if selected
      //unset free vote
      setIsLoading(true);
      customAxios
        .get(`/vote/verifyPayment?phone=${phoneNo}`)
        .then((res) => {
          const { data } = res;
          if (data.isPayed) {
            submitVotes();
          }
          else{
            setIsSubmitHit(false);
            navigate(`/payment?${params.toString()}`, {replace: true});
          }
        })
        .catch((err)=>{
          alert("server error") 
          console.err(err)
        })
        .finally(() => {
          setIsLoading(false);
      });
    }

    return (
        <div style={{position: "relative"}}>
            <SpinnerOverlay isLoading={isLoading} />
            <div className="ltitle">tell us who you are.</div>
            <div className="inputs">
                <input
                    ref={fullNameRef}
                    value={fullName}
                    onChange={handleSetFullName}
                    className={`form-control inp ${!isValidFullName ? "inp-warn" : ""}`}
                    type="text"
                    placeholder="name"
                    disabled={noOfVotes ? false : true}
                />
                <input
                    ref={phoneNoRef}
                    value={phoneNo}
                    onChange={handleSetPhoneNo}
                    className={`form-control inp ${!isValidPhoneNo ? "inp-warn" : ""}`}
                    type="tel"
                    placeholder="phone"
                    disabled={noOfVotes ? false : true}
                />
                <button
                    onClick={handleSubmitHit}
                    disabled={noOfVotes ? false : true}
                    className="form-control btn"
                >
                    submit
                </button>
            </div>
        </div>
    );
}

const successStyle = css`
  padding: 5rem 0;
  text-align: center;
  .success-text{
    color: darkcyan;
  }
  .success-text.m{
    font-weight: bold;
  }
`

function SubmitSuccess(){
    return (
      <div css={successStyle}>
        <div className="success-text m">
          thank you!!!
        </div>
        <div className="success-text">
          your votes have been submitted successfully.
        </div>
      </div>
    )
}
export default NewSubmitModal;