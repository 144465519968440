/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";

const style = css`
    display: flex;
    justify-content: center;
    align-items: center;
    .first-place{
        order: 2;
    }
    .first-place .vote, .first-place img{
        height: 6rem !important;
        width: 6rem !important;
    }
    .second-place{
        order: 1;
    }
    .third-place{
        order: 3;
    }
`;

const placeClasses = ["first-place", "second-place", "third-place"];
function Voted({ votes, revokeVote, continueVoting }){
    return (
        <div css={style}>
            {
                <>
                <div className={placeClasses[0]}>
                {
                    votes.length > 0 
                    ?
                    <Vote vote={votes[0]} rank={1} revokeVote={revokeVote} />
                    :   
                    <NextVote rank={1} isNext={true} continueVoting={continueVoting} />
                }
                </div>
                <div className={placeClasses[1]}>
                {
                    votes.length > 1 
                    ?
                    <Vote vote={votes[1]} rank={2} revokeVote={revokeVote} />
                    :   
                    <NextVote rank={2} isNext={votes.length === 1 ? true: false} continueVoting={continueVoting} />
                }
                </div>
                <div className={placeClasses[2]}>
                {
                    votes.length > 2 
                    ?
                    <Vote vote={votes[2]} rank={3} revokeVote={revokeVote} />
                    :   
                    <NextVote rank={3} isNext={votes.length === 2 ? true: false} continueVoting={continueVoting} />
                }
                </div>
                </>
            }
        </div>
    )
}

const colors = ["goldenrod", "silver", "#CD7F32"];
const voteStyle = css`
display: flex;
flex-direction: column;
align-items: center;
.name{
    width: 5rem;
    text-align: center;
}
.ph{
    opacity: .7;
}
.vote{
    display: flex;
    justify-content: center;
    align-items: center;
    border: "2px solid darkcyan";
    border-radius: 50%;
    margin: 1rem;
    width: 5rem;
    height: 5rem;
    background-color: white;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
    position: relative;
    svg{
        fill: rgba(0, 0, 0, 0.2);
    }
}
.next{
    svg{
        fill: darkcyan;
    }
    box-shadow: 0 0 3px 3px darkcyan;
    &:hover{
        background-color: darkcyan;
        svg{
            fill: white !important;
        }
    }
}
img{
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 50%;
}
.rank{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -1rem;
    left: 34%;
    color:  white;
    padding: .2rem;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    font-size: 15px;
    font-weight: bold;
}
.revoke{
    position: absolute;
    right: -1rem;
    top: -1rem;
    height: 2rem;
    width: 2rem;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background-color: #00000050;
    color: white;
    font-size: x-large;
    display: flex;
    align-items: center;
    justify-content: center;
}
`;

function Vote({ vote, rank, revokeVote }){
    const { id, pics } = vote;

    return (
        <div css={voteStyle} >
            <div className="vote" >
                <div className="rank" style={{backgroundColor: colors[rank-1]}}>{rank}</div>
                    <img src={pics[0].url} alt={rank} />
                <button onClick={()=>revokeVote(id)} className="revoke">
                    -
                </button>
            </div>
            <span className="name">
                { vote.name }
            </span>
        </div>
    )
}

function NextVote({ rank, isNext, continueVoting }){

    function handleContVote(){
        if( isNext ){
            continueVoting();
        }
    }

    if (rank>3) return null;
    // console.log("rank: ", rank)
    return (
        <div css={voteStyle}>
            <div className={isNext? "vote next": "vote"} onClick={handleContVote}>
                <div className="rank" style={{backgroundColor: colors[rank-1]}}>{rank}</div>
                <svg xmlns="http://www.w3.org/2000/svg" 
                    width="32" 
                    height="32" 
                    id="plus">
                    <path d="M9 18h7v7a1 1 0 0 0 2 0v-7h7a1 1 0 0 0 0-2h-7V9a1 1 0 0 0-2 0v7H9a1 1 0 0 0 0 2z"></path>
                </svg>
            </div>
            <span className="name ph">
                not selected
            </span>
        </div>
    )
}

export default Voted;